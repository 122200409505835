/*
 * TOPIAM Employee - Employee Identity and Access Management
 * Copyright © 2022-Present Jinan Yuanchuang Network Technology Co., Ltd. (support@topiam.cn)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
export default {
  'menu.portal': '门户中心',
  'menu.console': '后台管理',
  'menu.welcome': '欢迎',
  'menu.login': '登录',
  'menu.reset-password': '重置密码',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.profile': '个人中心',
  'menu.settings': '个人设置',
  'menu.logout': '退出登录',
  'menu.social-bind': '用户绑定',

  'menu.portal.app': '我的应用',
  'menu.portal.profile': '我的账户',
  'menu.portal.audit': '操作日志',
  'menu.portal.session': '会话管理',

  'menu.console.audit': '行为审计',
  'menu.console.audit.user': '用户行为',
  'menu.console.audit.admin': '管理员行为',
  'menu.console.session': '在线用户',

  'menu.console.dashboard': '总览',
  'menu.console.app': '应用管理',
  'menu.console.app.list': '应用列表',
  'menu.console.app.group': '应用分组',
  'menu.console.app.create': '创建应用',
  'menu.console.app.detail': '应用详情',
  'menu.console.account': '账户管理',
  'menu.console.account.user': '组织与用户',
  'menu.console.account.user.detail': '用户详情',
  'menu.console.account.user-group': '用户组管理',
  'menu.console.account.user-group.detail': '用户组详情',
  'menu.console.account.identity-source': '身份源管理',
  'menu.console.account.identity-source.detail': '身份源详情',
  'menu.console.authn': '认证管理',
  'menu.console.authn.identity_provider': '身份提供商',
  'menu.console.authorization': '授权管理',
  'menu.console.authorization.system.details': '系统详情',
  'menu.console.authorization.application': '应用授权',
  'menu.console.authorization.application.details': '应用详情',

  'menu.console.security': '安全设置',
  'menu.console.security.setting': '通用安全',
  'menu.console.security.password-policy': '密码策略',
  'menu.console.security.defense-strategy': '防御策略',
  'menu.console.security.administrator': '系统管理员',

  'menu.console.setting': '系统设置',
  'menu.console.setting.basic': '基础配置',
  'menu.console.setting.message': '消息设置',
  'menu.console.setting.geoip': 'IP地理库',
  'menu.console.setting.storage': '存储配置',
  'menu.console.session-expired': '会话过期',
};
