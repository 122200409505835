/*
 * TOPIAM Employee - Employee Identity and Access Management
 * Copyright © 2022-Present Jinan Yuanchuang Network Technology Co., Ltd. (support@topiam.cn)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
export default {
  'pages.setting.security.password_policy.desc':
    '系统密码相关策略，例如密码最小长度、密码中同一字符连续出现的最大次数、密码不能与历史密码相同，保证用户在修改密码时，新密码都是满足密码策略的复杂程度高的强密码。',
  'pages.setting.security.password_policy.password_length_limit': '密码长度限制',
  'pages.setting.security.password_policy.password_least_length': '最小长度',
  'pages.setting.security.password_policy.password_biggest_length': '最大长度',
  'pages.setting.security.password_policy.password_complexity': '密码复杂度',
  'pages.setting.security.password_policy.0': '任意密码',
  'pages.setting.security.password_policy.1': '必须包含数字和字母',
  'pages.setting.security.password_policy.2': '必须包含数字和大写字母',
  'pages.setting.security.password_policy.3': '必须包含数字、大写字母、小写字母、和特殊字符',
  'pages.setting.security.password_policy.4': '至少包含数字、字母、和特殊字符中的两种',
  'pages.setting.security.password_policy.5':
    '至少包含数字、大写字母、小写字母、和特殊字符中的三种',
  'pages.setting.security.password_policy.password_expiration_check': '密码过期检查',
  'pages.setting.security.password_policy.password_expiration_check.tooltip':
    '开启后，系统会计算密码设置时间',
  'pages.setting.security.password_policy.password_expiration_check.expiration-date': '有效期',
  'pages.setting.security.password_policy.password_expiration_check.day': '天',
  'pages.setting.security.password_policy.before_expiration': '过期前',
  'pages.setting.security.password_policy.before_expiration.day': '天提醒',
  'pages.setting.security.password_policy.password_same_characters': '密码相同字符',
  'pages.setting.security.password_policy.password_same_characters.tooltip':
    '密码不能连续存在相同字符个数',
  'pages.setting.security.password_policy.user_info_check': '用户信息检查',
  'pages.setting.security.password_policy.user_info_check.tooltip':
    '开启后，密码中将不能包含用户名、手机号、邮箱前缀和姓名拼音',
  'pages.setting.security.password_policy.hist_password_check': '历史密码检查',
  'pages.setting.security.password_policy.hist_password_check.tooltip':
    '检查设置的密码是否为历史密码。',
  'pages.setting.security.password_policy.range_password_history': '历史密码次数范围',
  'pages.setting.security.password_policy.range_password_history.tooltip':
    '检查历史密码次数范围，该值必须介于1到10次密码之间。',
  'pages.setting.security.password_policy.illegal-character-sequences': '非法字符序列',
  'pages.setting.security.password_policy.illegal_character_sequences.tooltip':
    '防止非法字符序列，例如键盘、字母、数字。',
  'pages.setting.security.password_policy.weak_password_checking': '弱密码检查',
  'pages.setting.security.password_policy.weak_password_checking.tooltip':
    '弱密码通常是指容易被别人猜测到或被破解工具破解的密码。在设置或修改密码时，如果密码是弱密码字典中的密码，则系统会强制用户重新设置，以提升用户密码的安全性。',
  'pages.setting.security.password_policy.weak_password_checking.password_library': '内置弱密码库',
  'pages.setting.security.password_policy.other_weak_passwords': '其他弱密码',
  'pages.setting.security.password_policy.other_weak_passwords.extra': '每行代表一条弱密码',
};
