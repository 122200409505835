// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/src/pages/app/AppDetail/model';
import model_2 from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'app.AppDetail.model', model: model_1 },
model_2: { namespace: '@@initialState', model: model_2 },
} as const
