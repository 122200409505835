// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import dayjs from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs';
import antdPlugin from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/antd-dayjs-webpack-plugin@1.0.6_dayjs@1.11.13/node_modules/antd-dayjs-webpack-plugin/src/antd-plugin.js';

import isSameOrBefore from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/isSameOrBefore';
import isSameOrAfter from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/isSameOrAfter';
import advancedFormat from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/advancedFormat';
import customParseFormat from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/customParseFormat';
import weekday from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/weekday';
import weekYear from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/weekYear';
import weekOfYear from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/weekOfYear';
import isMoment from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/isMoment';
import localeData from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/localeData';
import localizedFormat from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/localizedFormat';
import duration from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/dayjs@1.11.13/node_modules/dayjs/plugin/duration';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(weekYear);
dayjs.extend(weekOfYear);
dayjs.extend(isMoment);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(duration);

dayjs.extend(antdPlugin);
