/*
 * TOPIAM Employee - Employee Identity and Access Management
 * Copyright © 2022-Present Jinan Yuanchuang Network Technology Co., Ltd. (support@topiam.cn)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
export default {
  'pages.setting.storage': '文件存储',
  'pages.setting.storage.des': '配置云存储服务，如阿里云、腾讯云、MinIO等。',
  'pages.setting.storage_provider': '存储提供商',
  'pages.setting.storage_provider.switch.content': '关闭此功能将无法使用存储服务，请确认是否关闭。',
  'pages.setting.storage_provider.provider': '存储服务商',
  'pages.setting.storage_provider.provider.aliyun_oss': '阿里云OSS',
  'pages.setting.storage_provider.provider.tencent_cos': '腾讯云',
  'pages.setting.storage_provider.provider.qiniu_kodo': '七牛云Kodo',
  'pages.setting.storage_provider.provider.minio': 'MinIO',
  'pages.setting.storage_provider.provider.s3': 'S3',
  'pages.setting.storage_provider.provider.s3.endpoint': 'S3域名',
  'pages.setting.storage_provider.provider.s3.endpoint.placeholder': '请输入 S3域名',
  'pages.setting.storage_provider.provider.s3.endpoint.rule.0.message': 'S3域名为必填项',
  'pages.setting.storage_provider.provider.s3.endpoint.rule.1.message': 'S3域名格式不正确',
  'pages.setting.storage_provider.provider.s3.domain': '外链域名',
  'pages.setting.storage_provider.provider.s3.domain.placeholder': '请输入外链域名',
  'pages.setting.storage_provider.provider.s3.domain.rule.0.message': '外链域名为必填项',
  'pages.setting.storage_provider.provider.s3.domain.rule.1.message': '外链域名格式不正确',
  'pages.setting.storage_provider.provider.s3.access_key_id.placeholder': '请输入S3 AccessKeyId',
  'pages.setting.storage_provider.provider.s3.access_key_id.rule.0.message':
    'S3 AccessKeyId为必填项',
  'pages.setting.storage_provider.provider.s3.secret_access_key.placeholder':
    '请输入S3 SecretAccessKey',
  'pages.setting.storage_provider.provider.s3.secret_access_key.rule.0.message':
    'S3 SecretAccessKey为必填项',
  'pages.setting.storage_provider.provider.s3.region.placeholder': '请输入S3 Region',
  'pages.setting.storage_provider.provider.s3.bucket.placeholder': '请输入S3 Bucket',
  'pages.setting.storage_provider.provider.s3.bucket.rule.0.message': 'S3 Bucket为必填项',
  'pages.setting.storage_provider.provider.aliyun_oss.endpoint': '阿里云域名',
  'pages.setting.storage_provider.provider.aliyun_oss.endpoint.placeholder': '请输入阿里云域名',
  'pages.setting.storage_provider.provider.aliyun_oss.endpoint.rule.0.message':
    '阿里云域名为必填项',
  'pages.setting.storage_provider.provider.aliyun_oss.endpoint.rule.1.message':
    '阿里云域名格式不正确',
  'pages.setting.storage_provider.provider.aliyun_oss.domain': '外链域名',
  'pages.setting.storage_provider.provider.aliyun_oss.domain.placeholder': '请输入外链域名',
  'pages.setting.storage_provider.provider.aliyun_oss.domain.rule.0.message': '外链域名为必填项',
  'pages.setting.storage_provider.provider.aliyun_oss.domain.rule.1.message': '外链域名格式不正确',
  'pages.setting.storage_provider.provider.aliyun_oss.access_key_id.placeholder':
    '请输入阿里云AccessKeyId',
  'pages.setting.storage_provider.provider.aliyun_oss.access_key_id.rule.0.message':
    '阿里云AccessKeyId为必填项',
  'pages.setting.storage_provider.provider.aliyun_oss.access_key_secret.placeholder':
    '请输入阿里云AccessKeySecret',
  'pages.setting.storage_provider.provider.aliyun_oss.access_key_secret.rule.0.message':
    '阿里云AccessKeySecret为必填项',
  'pages.setting.storage_provider.provider.aliyun_oss.region.placeholder': '请输入阿里云Region',
  'pages.setting.storage_provider.provider.aliyun_oss.bucket.placeholder': '请输入阿里云Bucket',
  'pages.setting.storage_provider.provider.aliyun_oss.bucket.rule.0.message':
    '阿里云Bucket为必填项',
  'pages.setting.storage_provider.provider.tencent_cos.domain': '外链域名',
  'pages.setting.storage_provider.provider.tencent_cos.domain.placeholder': '请输入外链域名',
  'pages.setting.storage_provider.provider.tencent_cos.domain.rule.0.message': '外链域名为必填项',
  'pages.setting.storage_provider.provider.tencent_cos.domain.rule.1.message': '外链域名格式不正确',
  'pages.setting.storage_provider.provider.tencent_cos.app_id.placeholder': '请输入腾讯云AppId',
  'pages.setting.storage_provider.provider.tencent_cos.app_id.rule.0.message':
    '腾讯云AppId为必填项',
  'pages.setting.storage_provider.provider.tencent_cos.secret_id.placeholder':
    '请输入腾讯云SecretId',
  'pages.setting.storage_provider.provider.tencent_cos.secret_id.rule.0.message':
    '腾讯云SecretId为必填项',
  'pages.setting.storage_provider.provider.tencent_cos.secret_key.placeholder':
    '请输入腾讯云SecretKey',
  'pages.setting.storage_provider.provider.tencent_cos.secret_key.rule.0.message':
    '腾讯云SecretKey为必填项',
  'pages.setting.storage_provider.provider.tencent_cos.region.placeholder': '请输入腾讯云Region',
  'pages.setting.storage_provider.provider.tencent_cos.region.rule.0.message':
    '腾讯云Region为必填项',
  'pages.setting.storage_provider.provider.tencent_cos.bucket.placeholder': '请输入腾讯云Bucket',
  'pages.setting.storage_provider.provider.tencent_cos.bucket.rule.0.message':
    '腾讯云Bucket为必填项',
  'pages.setting.storage_provider.provider.qiniu_kodo.domain': '外链域名',
  'pages.setting.storage_provider.provider.qiniu_kodo.domain.placeholder': '请输入外链域名',
  'pages.setting.storage_provider.provider.qiniu_kodo.domain.rule.0.message': '外链域名为必填项',
  'pages.setting.storage_provider.provider.qiniu_kodo.domain.rule.1.message': '外链域名格式不正确',
  'pages.setting.storage_provider.provider.qiniu_kodo.access_key.placeholder':
    '请输入七牛云AccessKey',
  'pages.setting.storage_provider.provider.qiniu_kodo.access_key.rule.0.message':
    '七牛云AccessKey为必填项',
  'pages.setting.storage_provider.provider.qiniu_kodo.secret_key.placeholder':
    '请输入七牛云SecretKey',
  'pages.setting.storage_provider.provider.qiniu_kodo.secret_key.rule.0.message':
    '七牛云SecretKey为必填项',
  'pages.setting.storage_provider.provider.qiniu_kodo.region.placeholder': '请输入七牛云Region',
  'pages.setting.storage_provider.provider.qiniu_kodo.bucket.placeholder': '请输入七牛云Bucket',
  'pages.setting.storage_provider.provider.qiniu_kodo.bucket.rule.0.message':
    '七牛云Bucket为必填项',
  'pages.setting.storage_provider.provider.minio.endpoint': 'MinIO 域名',
  'pages.setting.storage_provider.provider.minio.endpoint.placeholder': '请输入MinIO 域名',
  'pages.setting.storage_provider.provider.minio.endpoint.rule.0.message': 'MinIO 域名为必填项',
  'pages.setting.storage_provider.provider.minio.endpoint.rule.1.message': 'MinIO 域名格式不正确',
  'pages.setting.storage_provider.provider.minio.access_key.placeholder': '请输入MinIO AccessKey',
  'pages.setting.storage_provider.provider.minio.access_key.rule.0.message':
    'MinIO AccessKey为必填项',
  'pages.setting.storage_provider.provider.minio.secret_key.placeholder': '请输入MinIO SecretKey',
  'pages.setting.storage_provider.provider.minio.secret_key.rule.0.message':
    'MinIO SecretKey为必填项',
  'pages.setting.storage_provider.provider.minio.region.placeholder': '请输入MinIO Region',
  'pages.setting.storage_provider.provider.minio.bucket.placeholder': '请输入MinIO Bucket',
  'pages.setting.storage_provider.provider.minio.bucket.rule.0.message': 'MinIO Bucket为必填项',
};
