/*
 * TOPIAM Employee - Employee Identity and Access Management
 * Copyright © 2022-Present Jinan Yuanchuang Network Technology Co., Ltd. (support@topiam.cn)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import { getCurrentUser } from './services/user';
import { requestConfig } from '@/request';

/**
 * 获取当前用户信息
 */
const fetchUserInfo = async (): Promise<API.CurrentUser | undefined> => {
  const result = await getCurrentUser().catch(() => undefined);
  if (result?.success && result.result) {
    return result.result;
  }
  return undefined;
};

export async function getInitialState(): Promise<{
  fetchUserInfo: () => Promise<API.CurrentUser | undefined>;
  currentUser?: API.CurrentUser;
}> {
  /**控制台打印*/
  console.log('%c欢迎使用 TOPIAM 企业数字身份管控平台', 'font-size: 24px;');

  return {
    fetchUserInfo,
  };
}

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  ...requestConfig,
};
